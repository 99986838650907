import React from "react"
import tw from "twin.macro"
import { Link, graphql } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import VideoHero from "@components/videoHero"
import Container from "@components/container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { heading1, Heading2 } from "@styles/ui"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import Quiz from "@components/quiz/ih/Quiz"
import quizData from "@components/quiz/ih/data"

// Assets
import heroVideoDesktop from "@videos/ih-condition-hero-desktop.mp4"
import heroVideoMobile from "@videos/ih-condition-hero-mobile.mp4"
import daytimeSleepy from "@images/ih-condition/daytime-sleepy.svg"
import wakingUp from "@images/ih-condition/waking-up.svg"
import brainFog from "@images/ih-condition/brain-fog.svg"
import clock from "@images/ih-condition/clock.svg"
import butterfly from "@images/ih-condition/butterfly.svg"

import { RECAPTCHA_3_KEY } from "@utils/constants"

const IHCondition = ({ location, data }) => {
  return (
    <Layout
      location={location}
      copyright={
        <>
          &copy; 2024 Jazz Pharmaceuticals plc{" "}
          <nobr>US-XYW-2400101 Rev0624</nobr>
        </>
      }
      isOrphanAlt
      footerType="orphan"
    >
      <Seo
        title="FDA-Approved Treatment For Idiopathic Hypersomnia | XYWAV®"
        description="XYWAV® (calcium, magnesium, potassium, and sodium oxybates) treatment for Idiopathic Hypersomnia (IH). Take the IH quiz to help your doctor better understand your symptoms. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <VideoHero
        isIh
        video={{
          mobile: {
            src: heroVideoMobile,
            additionalStyles: tw`max-sm:(!h-full [video]:(!w-auto)) sm:(top-0 bottom-auto)`,
          },
          desktop: {
            src: heroVideoDesktop,
            additionalStyles: tw`md:(top-auto bottom-[-50px])`,
          },
        }}
        poster={{
          mobile: {
            src: data.videoPosterMobile,
            position: "center top",
            srcPath: "/images/ih-condition/video-poster-mobile.jpg",
          },
          desktop: {
            src: data.videoPosterDesktop,
            position: "right center",
            srcPath: "/images/ih-condition/video-poster-desktop.jpg",
          },
        }}
      >
        <h1
          css={[
            heading1,
            tw`bg-[rgba(0,0,0,0.01)] text-[26px] pb-0 mb-2 lg:(text-[36px] mb-4)`,
          ]}
        >
          Change is possible
          <br />
          with XYWAV
        </h1>
        <h2
          css={[
            tw`(bg-[rgba(0,0,0,0.01)] text-[16px] leading-[22px] text-white font-bold) lg:(text-[22px] leading-[30.8px])`,
          ]}
        >
          Ask your doctor about the first and only FDA-approved treatment for
          Idiopathic Hypersomnia.
        </h2>
      </VideoHero>

      {/* List & Image */}
      <section tw="pt-8 lg:(pt-[72px] scroll-mt-[80px])">
        <Container isIh addedStyles={tw`px-5`}>
          <h2 css={[Heading2, tw`mb-5 text-studio lg:mb-8`]}>
            You&apos;ve tried different ways to manage your Idiopathic
            Hypersomnia. It&nbsp;may be time to try&nbsp;XYWAV
          </h2>
          <p tw="mb-8 lg:mb-14">
            Unlike stimulants that are taken during the day, XYWAV is taken at
            night to help treat multiple symptoms of Idiopathic Hypersomnia
            (IH), such&nbsp;as:
          </p>
          <div tw="flex flex-col lg:(flex-row justify-between)">
            <ul tw="text-[15px] mb-8 lg:(text-[16px] w-[474px] mb-0)">
              {symptomsData.map((symptom, index) => (
                <li
                  tw="flex items-center mb-4 last:mb-0 lg:(mb-6 last:mb-0)"
                  key={`symptom-${index}`}
                >
                  <img
                    src={symptom.image}
                    tw="w-[96px] mr-6"
                    alt={symptom.alt}
                  />
                  <div>
                    <h3 tw="font-bold text-[18px] lg:text-[20px]">
                      {symptom.header}
                    </h3>
                    <p tw="text-[15px] lg:text-[16px]">{symptom.copy}</p>
                  </div>
                </li>
              ))}
            </ul>
            <GatsbyImage
              image={getImage(data.dualImage)}
              alt="Split image with a man sleeping on the left and the same man is outside with his dog on the right"
              tw="w-full rounded-tl-[56px] rounded-tr-[24px] rounded-bl-[24px] rounded-br-[56px] lg:w-[624px]"
            />
          </div>
        </Container>
      </section>

      {/* Quiz */}
      <section tw="py-8 lg:(py-[72px] scroll-mt-[80px])" id="ih-symptoms-quiz">
        <Container isIh addedStyles={tw`px-5`}>
          <div tw="flex flex-col mb-8 lg:(flex-row items-start pl-14 pr-[160px] mb-14)">
            <img
              src={butterfly}
              alt="Butterfly icon"
              tw="w-14 mb-2 lg:(mr-[17px] mb-0)"
            />
            <div>
              <h2 css={[Heading2, tw`mb-2 text-studio`]}>
                Still experiencing IH symptoms?
              </h2>
              <p>
                If you&apos;re tired of workarounds—like setting multiple alarms
                and drinking extra coffee—take this short quiz. The answers will
                help you talk to your sleep specialist about how XYWAV
                can&nbsp;help.
              </p>
            </div>
          </div>

          <div id="quiz" tw="lg:scroll-mt-20">
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_3_KEY}>
              <Quiz pageId={"ih-condition"} quizData={quizData} />
            </GoogleReCaptchaProvider>
          </div>
        </Container>
      </section>

      {/* Patient Quote */}
      <section tw="py-4 lg:(py-12 scroll-mt-[80px])">
        <Container isIh addedStyles={tw`px-5`}>
          <div tw="flex flex-col lg:(flex-row items-center justify-between)">
            <GatsbyImage
              image={getImage(data.patient)}
              alt="A headshot of Diana, XYWAV® IH Ambassador"
              tw="w-full mb-11 lg:(w-[470px] mr-[64px] mb-0)"
            />
            <div tw="w-full lg:w-[647px]">
              <p
                css={[
                  Heading2,
                  tw`mb-6 text-studio text-[24px] leading-[30px] lg:(text-[28px] leading-[36px])`,
                ]}
              >
                <span tw="ml-[-13px] lg:ml-[-17px]">“</span>My life used to be
                consumed by sleep, but taking XYWAV twice nightly helps me fight
                daytime sleepiness. I wake up each morning with less sleep
                inertia and don&apos;t need to nap as much during the day.”
              </p>
              <p tw="mb-0.5 text-[15px] font-bold lg:text-[23px]">Diana</p>
              <p tw="mb-6 text-[15px] italic lg:text-[18px]">
                XYWAV IH Ambassador
              </p>
              <p tw="text-[12px] lg:text-[14px]">
                Real patient compensated for their testimonial. Individual
                results may vary.
              </p>
            </div>
          </div>
        </Container>
      </section>

      {/* Callout */}
      <section tw="py-8 orphan-callout-gradient lg:(py-[72px] scroll-mt-[80px])">
        <Container isIh addedStyles={tw`px-5`}>
          <div tw="bg-white flex flex-col px-8 py-10 rounded-tl-[40px] rounded-tr-[16px] rounded-br-[40px] rounded-bl-[16px] lg:(flex-row items-center justify-between px-[100px] py-16 rounded-tl-[72px] rounded-tr-[32px] rounded-br-[72px] rounded-bl-[32px])">
            <div tw="mb-8 lg:(mb-0 w-[570px])">
              <h2 css={[Heading2, tw`mb-2 text-studio lg:mb-4`]}>
                The information you need
              </h2>
              <p tw="text-[15px] lg:(text-[16px] pr-12)">
                Get more information about XYWAV as a treatment for your IH, so
                you and your doctor can decide if XYWAV is right for you.
              </p>
            </div>
            <Link
              className="xw-nav__button group"
              to={"/idiopathic-hypersomnia/stay-connected/"}
              tw="bg-coral-red mx-auto text-base text-center px-5 inline-flex justify-center items-center uppercase font-black text-white rounded-full transition-main cursor-pointer relative z-10 w-[230px] h-[55px] hover:(bg-merlin text-white) focus-visible:outline-0 lg:(w-[350px] h-[55px] mx-[unset])"
            >
              Sign Up Today
            </Link>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default IHCondition

export const query = graphql`
  query {
    dualImage: file(relativePath: { eq: "ih-condition/dual-campaign.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1248, placeholder: NONE)
      }
    }
    patient: file(relativePath: { eq: "ih-condition/patient-image.png" }) {
      childImageSharp {
        gatsbyImageData(width: 940, placeholder: NONE)
      }
    }
    videoPosterDesktop: file(
      relativePath: { eq: "ih-condition/video-poster-desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 2880, placeholder: NONE)
      }
    }
    videoPosterMobile: file(
      relativePath: { eq: "ih-condition/video-poster-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 750, placeholder: NONE)
      }
    }
  }
`

const symptomsData = [
  {
    header: "Excessive daytime sleepiness",
    copy: "feeling very sleepy or tired during the day",
    image: daytimeSleepy,
    alt: "Person sleeping at desk icon",
  },
  {
    header: "Sleep inertia",
    copy: "severe grogginess or confusion when waking up",
    image: wakingUp,
    alt: "Person sitting in a bed icon",
  },
  {
    header: "Cognitive impairment",
    copy: "“brain fog”",
    image: brainFog,
    alt: "A person's head with a cloud inside of it",
  },
  {
    header: "Long sleep time",
    copy: "ability to sleep for 11+ hours",
    image: clock,
    alt: "Clock icon",
  },
]
